import React from "react";
import CMPPAGE from "../components/Cmp/CmpPage";
import Layout from '../components/layout'

const CMP = () =>{
return(
<Layout>
     <CMPPAGE />
</Layout>
)
}

export default CMP
