import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import MDEditor from '@uiw/react-md-editor';
import './cmp.css'
import { IMAGELINK } from "../../utils/constants";

const CMPPAGE = () =>{

    const data = useStaticQuery(graphql`
    {
      allStrapiCmpsponsers {
        nodes {
          cmpsponser {
            SubtitleAlignment
            SubtitleFontColor
            SubtitleFontFamily
            SubtitleFontSize
            SubtitleFontWeight
            Subtitle_Name
            TitleAlignment
            TitleFontColor
            TitleFontFamily
            TitleFontSize
            TitleFontWeight
            TitleName
            Description
          }
          para
        }
      }
      allStrapiCmps {
        nodes {
          cmp_bg
        }
      }
    }
  `)

  var cmp = data.allStrapiCmpsponsers.nodes
  const cmpdynamic = cmp[0].cmpsponser

  let CmpContent = cmpdynamic.map(element=>{
    console.log(element)
    if(element.TitleName !=null && element.Subtitle_Name ==null){
     return <div><h1 style = {{paddingBottom: "30px", textAlign: element.TitleAlignment, color:element.TitleFontColor,fontFamily:element.TitleFontFamily,fontSize:element.TitleFontSize,fontWeight:"400"}}>{element.TitleName} </h1><MDEditor.Markdown source={cmp[0].para} /></div>
     }
     else if(element.Subtitle_Name != null && element.Description ==null && element.TitleName == null){
         return <h3 style = {{textAlign: element.SubtitleAlignment, color:element.SubtitleFontColor,fontFamily:element.SubtitleFontFamily,fontSize:element.SubtitleFontSize,fontWeight:element.SubtitleFontWeight}}>{element.Subtitle_Name}</h3>
     }
     else if(element.Description !=null){
         return <p style={{paddingBottom: "5px"}} ><MDEditor.Markdown className="cmpdescriptionProperties" source={element.Description} /></p>
     } 
     
})

var image = IMAGELINK + '/uploads/CMP_Logo_c2a96ebcd3.png'


return(
<div>
    <div className="card cmp_card" style={{backgroundColor:data.allStrapiCmps.nodes[0].cmp_bg}}>
    <div className="container">
      <div style={{textAlign: "center"}}>
        <h1 style = {{paddingBottom: "30px", color:"black",fontFamily:"arial",fontSize:"28px",fontWeight:"600"}}>CMP SPONSOR</h1>
      </div>
      <div>
      {CmpContent}
      </div>
      <div style={{textAlign: "center"}}>
        <img src={image} className="logo"/>
      </div>
    </div>
    </div>
</div>
)
}

export default CMPPAGE
